import * as React from "react";
import {useContext} from "react";
import {UserContext} from "../context/user.context";

export const AppBarUserLogo = () => {

    const {user} = useContext(UserContext);


    return user.firm_id === "5899" ? <img src="/images/bank3Logo.png" height="100%" alt="Bank 3"/>: <img src="/images/bank1Logo.png" height="100%" alt="Bank 1"/>
}


export const FooterUserLogo = () => {

    const {user} = useContext(UserContext);


    return user.firm_id === "5899" ? <img src="/images/bank3Logo.png" width="100px" height="75px" alt="Bank 3"/>: <img src="/images/bank1Logo.png" height="100%" alt="Bank 1"/>

}