const  USE_CASE_STATUS = {
    saved: {
        id: 'saved',
        translation: 'Gespeichert',
        color: 'success'
    },
    approved: {
        id: 'approved',
        translation: 'Freigegeben',
        color: 'success'
    },
    working: {
        id: 'working',
        translation: 'In Bearbeitung',
        color: 'warning'
    },
    error: {
        id: 'error',
        translation: 'Fehler',
        color: 'error'
    },
    success: {
        id: 'success',
        translation: 'Trainiert',
        color: 'success'
    }
};
export { USE_CASE_STATUS };
