import { createContext, useState } from 'react'

export const AllUseCasesContext = createContext({
    allUseCases: [],
    setAllUseCases: () => null,
});

export const AllUseCasesProvider = ({ children }) => {
    const [allUseCases, setAllUseCases] = useState([]);


    return <AllUseCasesContext.Provider value={{allUseCases, setAllUseCases}}>{children}</AllUseCasesContext.Provider>
}