import {experimental_sx as sx} from '@mui/material/styles';

export const myThemeOptions = {
    /* Custom variables */
    /**/headline: {
        fontColor: '#0061A7',
        iconColor: '#0061A7'
    },
    palette: {
        primary: {
            main: '#0061A7',
            contrastText: '#fff',
        },
        /*error: {
            main: '#FF0000',
            contrastText: '#fff'
        },
        warning: {
            main: '#FFE816',
            contrastText: '#000'
        },
        success: {
            main: '#68D86B',
            contrastText: '#000'
        },*/
        black: {
            main: '#2B2B2B',
            contrastText: '#fff',
        },
        blue: {
            main: '#0061A7',
            contrastText: '#fff',
            stepperLine: '#3185E6'
        },
        white: {
            main: '#ffffff',
        },
        gray: {
            main: '#707070',
            divider: '#B7B7B7',
            uploadFieldBg: '#F5F5F5'
        },
        disabled: {
            main: '#9f9f9f',
            divider: '#B7B7B7',
            uploadFieldBg: '#F5F5F5'
        },
        text: {
            secondary: '#505050',
            third: '#707070',
            fourth: '#909090',
            fifth: '#B0B0B0',
            sixth: '#D0D0D0',
            seventh: '#F0F0F0',
            eigth: '#FFFFFF'
        }
    },
    typography: {
        /* Standard  h1, h2, h3, h4, h5, h6, subtitle1, subtitle2, body1, body2, button, caption, overline */
        fontFamily: ['Calibri, sans-serif'],
        appBarLogo: {
            color: '#0061A7',
            fontWeight: 700,
            textDecoration: 'none',
            fontSize: '1.25rem'
        },
        appBarText: {
            color: '#000',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5
        },
        menu: {
            color: '#000',
            fontWeight: 500,
            fontSize: '1.2rem',
            lineHeight: 3.0
        },
        modalDialog: {
            color: '#fff',
            fontWeight: 700,
            fontSize: '1.2rem'
        },
        body1: {
            color: '#000'
        },
        body3: {
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: '1.3rem'
        },
        button: {
            fontSize: '1rem',
        },
        formLabels: {
            fontSize: '1rem'
        },
        formLabelsRequired: {
            fontSize: '1rem',
            color: '#981818'
        },
        formSubtiles: {
            fontSize: '1rem',
            fontWeight: 700,
        }
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            },
        },
        MuiTextField: {
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        borderRadius: '4px',
                        borderColor: '#B7B7B7',
                        backgroundColor: 'white',
                        boxShadow: 'none',
                        marginTop:  8
                    },
                }
            ]
        },
        MuiButton: {
            defaultProps: {
                disableRipple: false
            },
            variants: [
                {
                    props: {variant: 'bigfont'},
                    style: {
                        color: '#0061A7',
                        fontWeight: 200,
                        textDecoration: 'none',
                        fontSize: '1.25rem',
                        textTransform: 'none'
                    },
                }
            ]
        },
        MuiAppBar: {
            styleOverrides: {
                root: sx({
                    background: 'white',
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 10px 0px rgb(0 142 230 / 16%);',
                    color: 'black'
                })
            }
        },
        MuiTab: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.className === 'formTab' &&
                        !ownerState.selected && {
                            backgroundColor: '#A9A9A9',
                            color: '#fff',
                        }),
                    ...(ownerState.className === 'formTab' &&
                        ownerState.selected && {
                        backgroundColor: '#0061A7',
                        color: '#fff',
                    }),
                }),
            },
        },
        // TODO: MuiBadge-anchorOriginBottomRight --> verschieben nach oben
        MuiBadge: {
            styleOverrides: {
                anchorOriginBottomRightRectangular: ({ownerState}) => ({
                    ...(ownerState.anchorOrigin.vertical === 'bottom' &&
                        ownerState.anchorOrigin.horizontal === 'right' && {
                            bottom: 0
                        }),
                }),
            },
        },
        MuiDivider: {
            variants: [
                {
                    props: {variant: 'searchfield'},
                    style: {
                        backgroundColor: '#fff',
                        border: 'solid 2px #fff'
                    },
                },
                {
                    props: {variant: 'formparts'},
                    style: {
                        border: 'solid 1px #B7B7B7',
                        marginTop: '1rem',
                        marginBottom: '1rem'
                    },
                }
            ]
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    padding: '8px'
                },
                action: {
                    color: '#fff',
                }
            }
        },
        MuiCardMedia: {
            styleOverrides: {
                root: {
                    marginTop: '-48px'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '0px !important'
                }
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.disabled && {
                            color: '#A9A9A9'
                        }),
                    ...(!ownerState.disabled && {
                        color: '#2B2B2B'
                    })
                }),
                label: {
                    fontSize: '1rem'
                },
            }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#D1D1D1',
                    paddingTop: '40px',
                    paddingBottom: '20px'
                }
            }
        },
        MuiGrid: {
            styleOverrides: {
                root:  ({ownerState}) => ({
                    ...(ownerState.item &&
                        ownerState.className === "formGrids" && {
                            height: '56px',
                            display: 'flex',
                            justifyContent: 'left',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                    ...(ownerState.item &&
                        ownerState.className === "formGridsRGrp" && {
                            display: 'flex',
                            justifyContent: 'left',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                }),
            }
        }
    }
};


export const user1Theme = {
    /* Custom variables */
    /**/headline: {
        fontColor: '#A437DB',
        iconColor: '#A437DB'
    },
    palette: {
        primary: {
            main: '#A437DB',
            contrastText: '#fff',
        },
        /*error: {
            main: '#FF0000',
            contrastText: '#fff'
        },
        warning: {
            main: '#FFE816',
            contrastText: '#000'
        },
        success: {
            main: '#68D86B',
            contrastText: '#000'
        },*/
        black: {
            main: '#2B2B2B',
            contrastText: '#fff',
        },
        blue: {
            main: '#0061A7',
            contrastText: '#fff',
            stepperLine: '#3185E6'
        },
        white: {
            main: '#ffffff',
        },
        gray: {
            main: '#707070',
            divider: '#B7B7B7',
            uploadFieldBg: '#F5F5F5'
        },
        disabled: {
            main: '#9f9f9f',
            divider: '#B7B7B7',
            uploadFieldBg: '#F5F5F5'
        },
        text: {
            secondary: '#505050',
            third: '#707070',
            fourth: '#909090',
            fifth: '#B0B0B0',
            sixth: '#D0D0D0',
            seventh: '#F0F0F0',
            eigth: '#FFFFFF'
        }
    },
    typography: {
        /* Standard  h1, h2, h3, h4, h5, h6, subtitle1, subtitle2, body1, body2, button, caption, overline */
        fontFamily: ['Calibri, sans-serif'],
        appBarLogo: {
            color: '#A437DB',
            fontWeight: 700,
            textDecoration: 'none',
            fontSize: '1.25rem'
        },
        appBarText: {
            color: '#000',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5
        },
        menu: {
            color: '#000',
            fontWeight: 500,
            fontSize: '1.2rem',
            lineHeight: 3.0
        },
        modalDialog: {
            color: '#fff',
            fontWeight: 700,
            fontSize: '1.2rem'
        },
        body1: {
            color: '#000'
        },
        body3: {
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: '1.3rem'
        },
        button: {
            fontSize: '1rem',
        },
        formLabels: {
            fontSize: '1rem'
        },
        formLabelsRequired: {
            fontSize: '1rem',
            color: '#981818'
        },
        formSubtiles: {
            fontSize: '1rem',
            fontWeight: 700,
        }
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            },
        },
        MuiTextField: {
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        borderRadius: '4px',
                        borderColor: '#B7B7B7',
                        backgroundColor: 'white',
                        boxShadow: 'none',
                        marginTop:  8
                    },
                }
            ]
        },
        MuiButton: {
            defaultProps: {
                disableRipple: false
            },
            variants: [
                {
                    props: {variant: 'bigfont'},
                    style: {
                        color: '#A437DB',
                        fontWeight: 200,
                        textDecoration: 'none',
                        fontSize: '1.25rem',
                        textTransform: 'none'
                    },
                }
            ]
        },
        MuiAppBar: {
            styleOverrides: {
                root: sx({
                    background: 'white',
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 10px 0px rgb(0 142 230 / 16%);',
                    color: 'black'
                })
            }
        },
        MuiTab: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.className === 'formTab' &&
                        !ownerState.selected && {
                            backgroundColor: '#A9A9A9',
                            color: '#fff',
                        }),
                    ...(ownerState.className === 'formTab' &&
                        ownerState.selected && {
                            backgroundColor: '#A437DB',
                            color: '#fff',
                        }),
                }),
            },
        },
        // TODO: MuiBadge-anchorOriginBottomRight --> verschieben nach oben
        MuiBadge: {
            styleOverrides: {
                anchorOriginBottomRightRectangular: ({ownerState}) => ({
                    ...(ownerState.anchorOrigin.vertical === 'bottom' &&
                        ownerState.anchorOrigin.horizontal === 'right' && {
                            bottom: 0
                        }),
                }),
            },
        },
        MuiDivider: {
            variants: [
                {
                    props: {variant: 'searchfield'},
                    style: {
                        backgroundColor: '#fff',
                        border: 'solid 2px #fff'
                    },
                },
                {
                    props: {variant: 'formparts'},
                    style: {
                        border: 'solid 1px #B7B7B7',
                        marginTop: '1rem',
                        marginBottom: '1rem'
                    },
                }
            ]
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    padding: '8px'
                },
                action: {
                    color: '#fff',
                }
            }
        },
        MuiCardMedia: {
            styleOverrides: {
                root: {
                    marginTop: '-48px'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '0px !important'
                }
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.disabled && {
                        color: '#A9A9A9'
                    }),
                    ...(!ownerState.disabled && {
                        color: '#2B2B2B'
                    })
                }),
                label: {
                    fontSize: '1rem'
                },
            }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#D1D1D1',
                    paddingTop: '40px',
                    paddingBottom: '20px'
                }
            }
        },
        MuiGrid: {
            styleOverrides: {
                root:  ({ownerState}) => ({
                    ...(ownerState.item &&
                        ownerState.className === "formGrids" && {
                            height: '56px',
                            display: 'flex',
                            justifyContent: 'left',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                    ...(ownerState.item &&
                        ownerState.className === "formGridsRGrp" && {
                            display: 'flex',
                            justifyContent: 'left',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                    ...(ownerState.item &&
                        ownerState.className === "formTab" && {
                            height: '56px',
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                }),
            }
        }
    }
};

export const user2Theme = {
    /* Custom variables */
    /**/headline: {
        fontColor: '#30C48D',
        iconColor: '#30C48D'
    },
    palette: {
        primary: {
            main: '#30C48D',
            contrastText: '#fff',
        },
        /*error: {
            main: '#FF0000',
            contrastText: '#fff'
        },
        warning: {
            main: '#FFE816',
            contrastText: '#000'
        },
        success: {
            main: '#68D86B',
            contrastText: '#000'
        },*/
        black: {
            main: '#2B2B2B',
            contrastText: '#fff',
        },
        blue: {
            main: '#0061A7',
            contrastText: '#fff',
            stepperLine: '#3185E6'
        },
        white: {
            main: '#ffffff',
        },
        gray: {
            main: '#707070',
            divider: '#B7B7B7',
            uploadFieldBg: '#F5F5F5'
        },
        disabled: {
            main: '#9f9f9f',
            divider: '#B7B7B7',
            uploadFieldBg: '#F5F5F5'
        },
        text: {
            secondary: '#505050',
            third: '#707070',
            fourth: '#909090',
            fifth: '#B0B0B0',
            sixth: '#D0D0D0',
            seventh: '#F0F0F0',
            eigth: '#FFFFFF'
        }
    },
    typography: {
        /* Standard  h1, h2, h3, h4, h5, h6, subtitle1, subtitle2, body1, body2, button, caption, overline */
        fontFamily: ['Calibri, sans-serif'],
        appBarLogo: {
            color: '#30C48D',
            fontWeight: 700,
            textDecoration: 'none',
            fontSize: '1.25rem'
        },
        appBarText: {
            color: '#000',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5
        },
        menu: {
            color: '#000',
            fontWeight: 500,
            fontSize: '1.2rem',
            lineHeight: 3.0
        },
        modalDialog: {
            color: '#fff',
            fontWeight: 700,
            fontSize: '1.2rem'
        },
        body1: {
            color: '#000'
        },
        body3: {
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: '1.3rem'
        },
        button: {
            fontSize: '1rem',
        },
        formLabels: {
            fontSize: '1rem'
        },
        formLabelsRequired: {
            fontSize: '1rem',
            color: '#981818'
        },
        formSubtiles: {
            fontSize: '1rem',
            fontWeight: 700,
        }
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            },
        },
        MuiTextField: {
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        borderRadius: '4px',
                        borderColor: '#B7B7B7',
                        backgroundColor: 'white',
                        boxShadow: 'none',
                        marginTop:  8
                    },
                }
            ]
        },
        MuiButton: {
            defaultProps: {
                disableRipple: false
            },
            variants: [
                {
                    props: {variant: 'bigfont'},
                    style: {
                        color: '#30C48D',
                        fontWeight: 200,
                        textDecoration: 'none',
                        fontSize: '1.25rem',
                        textTransform: 'none'
                    },
                }
            ]
        },
        MuiAppBar: {
            styleOverrides: {
                root: sx({
                    background: 'white',
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 10px 0px rgb(0 142 230 / 16%);',
                    color: 'black'
                })
            }
        },
        MuiTab: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.className === 'formTab' &&
                        !ownerState.selected && {
                            backgroundColor: '#A9A9A9',
                            color: '#fff',
                        }),
                    ...(ownerState.className === 'formTab' &&
                        ownerState.selected && {
                            backgroundColor: '#30C48D',
                            color: '#fff',
                        }),
                }),
            },
        },
        // TODO: MuiBadge-anchorOriginBottomRight --> verschieben nach oben
        MuiBadge: {
            styleOverrides: {
                anchorOriginBottomRightRectangular: ({ownerState}) => ({
                    ...(ownerState.anchorOrigin.vertical === 'bottom' &&
                        ownerState.anchorOrigin.horizontal === 'right' && {
                            bottom: 0
                        }),
                }),
            },
        },
        MuiDivider: {
            variants: [
                {
                    props: {variant: 'searchfield'},
                    style: {
                        backgroundColor: '#fff',
                        border: 'solid 2px #fff'
                    },
                },
                {
                    props: {variant: 'formparts'},
                    style: {
                        border: 'solid 1px #B7B7B7',
                        marginTop: '1rem',
                        marginBottom: '1rem'
                    },
                }
            ]
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    padding: '8px'
                },
                action: {
                    color: '#fff',
                }
            }
        },
        MuiCardMedia: {
            styleOverrides: {
                root: {
                    marginTop: '-48px'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '0px !important'
                }
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.disabled && {
                        color: '#A9A9A9'
                    }),
                    ...(!ownerState.disabled && {
                        color: '#2B2B2B'
                    })
                }),
                label: {
                    fontSize: '1rem'
                },
            }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#D1D1D1',
                    paddingTop: '40px',
                    paddingBottom: '20px'
                }
            }
        },
        MuiGrid: {
            styleOverrides: {
                root:  ({ownerState}) => ({
                    ...(ownerState.item &&
                        ownerState.className === "formGrids" && {
                            height: '56px',
                            display: 'flex',
                            justifyContent: 'left',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                    ...(ownerState.item &&
                        ownerState.className === "formGridsRGrp" && {
                            display: 'flex',
                            justifyContent: 'left',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                    ...(ownerState.item &&
                        ownerState.className === "formTab" && {
                            height: '56px',
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                }),
            }
        }
    }
};
