import { createContext, useState } from 'react'

export const CompanyUseCasesContext = createContext({
    companyUseCases: [],
    setCompanyUseCases: () => null,
});

export const CompanyUseCasesProvider = ({ children }) => {
    const [companyUseCases, setCompanyUseCases] = useState([]);


    return <CompanyUseCasesContext.Provider value={{companyUseCases, setCompanyUseCases}}>{children}</CompanyUseCasesContext.Provider>
}