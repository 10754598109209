import React, {useContext, useState} from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {Divider, InputAdornment, Stack, TextField} from "@mui/material";
import UseCaseCard from "./use-case-card";
import {AllUseCasesContext} from "../context/use-cases/all-use-cases.context";
import Typography from "@mui/material/Typography";
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WindowIcon from '@mui/icons-material/Window';
import UseCaseModal from "./use-case-modal";
import {UserContext} from "../context/user.context";

const UseCase = () => {
    const {allUseCases} = useContext(AllUseCasesContext);
    const [searchTerm, setSearchTerm] = useState('');
    const {user} = useContext(UserContext)
    const [modalEditable, setModalEditable] = useState(false)
    const [clickedUseCase, setClickedUseCase] = useState();
    const [openUseCaseForm, setOpenUseCaseForm] = useState(false);

    const openForm = () => {
        setOpenUseCaseForm(true);
    };
    const closeForm = () => {
        setOpenUseCaseForm(false);
    }

    const checkEditAuth = (val) => {

        val.createdFromUserID === user.id? setModalEditable(true): setModalEditable(false)
    }


    return (
        <Container maxWidth="xl" >
            <TextField fullWidth id="outlined-search" variant="outlined"
                       sx={{mt: 8, boxShadow: '0px 1px 10px 0px rgb(0 142 230 / 16%);'}}
                       label="Suche..." type="search" onChange={event => {
                setSearchTerm(event.target.value)
            }}
                       InputProps={{
                           endAdornment: (
                               <InputAdornment position="end">
                                   <SearchIcon/>
                               </InputAdornment>
                           ),
                       }}
            />
            <Grid container spacing={2} sx={{mt:4}}>
                <Grid item xs={2}>
                    <Typography variant="subtitle1" color="text.primary" align="left" component="p">
                        {allUseCases.filter((val) => {
                            if (searchTerm === "") {
                                return val
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                val.description.toLowerCase().includes(searchTerm.toLowerCase())
                            ) {
                                return val
                            } else {
                                return null
                            }
                        }).length} Treffer
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Stack direction="row">
                        <Typography variant="subtitle1" color="text.primary" align="left" component="p">
                            Sortierung
                        </Typography>
                        <ArrowDropDownIcon/>
                    </Stack>
                </Grid>
                <Grid item xs={1} justifyContent="flex-end" alignItems="center">
                        <WindowIcon fontSize="small" color="blue"/>
                        <FormatListBulletedIcon fontSize="small" color="gray"/>
                </Grid>
            </Grid>
            <Divider variant="searchfield"/>
            {<Grid sx={{my: 1}} container spacing={4}>
                {allUseCases.filter((val) => {
                    if (searchTerm === "") {
                        return val
                    } else if (
                        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        val.description.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                        return val
                    } else {
                        return null
                    }
                }).map((val, key) => (
                        <Grid item key={val.id} xs={12} sm={5} md={4} lg={3}>
                            <UseCaseCard
                                key={val.id}
                                onClick={() => {
                                    openForm()
                                    setClickedUseCase(val)
                                    checkEditAuth(val)
                                }}
                                heading={val.title}
                                description={val.description}
                                image={val.id}
                                favorite={val.favorite}
                                date={val.createdAt? val.createdAt: "Kein Datum"}
                                invitedUserIds={val.invitedUserIds}
                                invitedCompanyIds={val.invitedCompanyIds}
                                numberOfApprovedCompanies={val.numberOfApprovedCompanies}
                                approvedByCompanyIds={val.approvedByCompanyIds}
                                stateId={val.state}
                                useCase={val} />
                        </Grid>
                ))}
                <UseCaseModal open={openUseCaseForm} close={closeForm} data={clickedUseCase} editable={modalEditable} newUseCase={false}></UseCaseModal>
            </Grid>}
        </Container>
    );
}

export default UseCase;
