import { createContext, useState } from 'react'
import MY_CONTENT from '../myContent.json';

export const MyContentContext = createContext({
    myContentData: {},
    setMyContentData: () => null,
    addNewData: (newData) => null,
    addMyOwnContentData: (newData) => null,
    addMyCompanyContentData: (newData) => null,
    addMyFavoritesContentData: (newData) => null,
});

export const MyContextProvider = ({ children }) => {
    const [myContentData, setMyContentData] = useState(MY_CONTENT);

    const addNewDataHandler = (newData) => {
        console.log('addMyOwnContentDataHandler');
    };

    const addMyOwnContentDataHandler = (newData) => {
        console.log('addMyOwnContentDataHandler');
    };

    const addMyCompanyContentDataHandler = (newData) => {
        console.log('addMyCompanyContentDataHandler');
    };

    const addMyFavoritesContentDataHandler = (newData) => {
        console.log('addMyFavoritesContentDataHandler');
        const index = myContentData.favorites.findIndex((item) => item.id === newData.id);
        if (index === -1) {
            myContentData.favorites.push(newData);
            setMyContentData({
                ...myContentData,
                'favorites': myContentData.favorites
            })
        } else {
            console.log('already in favorites');
        }
    };

    const contextValue = {
        myContentData,
        setMyContentData,
        addNewData: addNewDataHandler,
        addMyOwnContentData: addMyOwnContentDataHandler,
        addMyCompanyContentData: addMyCompanyContentDataHandler,
        addMyFavoritesContentData: addMyFavoritesContentDataHandler,
    };

    return <MyContentContext.Provider value={contextValue}>{children}</MyContentContext.Provider>
}