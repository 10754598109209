import './styles/app.css';
import {Button, FormControl, TextField, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import React, {useContext, useState} from "react";
import Box from "@mui/material/Box";
import {createTheme, ThemeProvider} from "@mui/material/styles";


import CssBaseline from "@mui/material/CssBaseline";
import {myThemeOptions} from "./MyTheme";
import {AuthContext} from "./context/auth.context";
import axios from "axios";

const theme = createTheme(myThemeOptions)

const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {setAuthentication, setToken} = useContext(AuthContext)

    const handleSubmit = async () => {
        axios.request({
            url: 'https://auth.dev-strategion.de/auth/realms/secai/protocol/openid-connect/token',
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: new URLSearchParams({
                'grant_type': 'password',
                'client_id': 'react-service',
                'username': email,
                'password': password,
            }).toString()
        }).then(res => {
            setToken(res.data.access_token);
            setAuthentication(true);
        }).catch(err => {
            console.log(err)
        })
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.code === "Enter") {
            handleSubmit();
        }
    };

    return(
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Container maxWidth="xxl" className="background" sx={{height: '100vh'}}>
                <Box sx={{height: '100%', width: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FormControl>
                        <Typography variant="h5" textAlign="center" mb={3}>Herzlich Willkommen!</Typography>
                        <TextField type="text" sx={{minWidth: '300px'}} placeholder="Email" value={email} onChange={handleEmailChange} onKeyPress={handleKeypress}></TextField>
                        <TextField type="password" sx={{minWidth: '300px', mb: 2}} placeholder="Passwort" value={password} onChange={handlePasswordChange} onKeyPress={handleKeypress}></TextField>
                        <Button onClick={handleSubmit} sx={{bgcolor: '#0076FF2F', borderColor: '#0076FF2F'}}>Anmelden</Button>
                    </FormControl>
                </Box>
            </Container>
        </ThemeProvider>

    )
}

export default Login;
