import * as React from 'react';
import {Divider} from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
//import {FooterUserLogo} from "../layouts/checkUserContent";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://strategion.de/">
                Strategion
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const Footer = () => {
    return (
        <Container sx={{py: 8}} maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
                {/*<Grid item xs={10} align="left" >
                    <FooterUserLogo/>
                </Grid>*/}
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" align="left" component="p">
                        Impressum
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" align="right" component="p">
                        Help
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Copyright/>
                </Grid>
            </Grid>
            {/* End footer */}
        </Container>
    );
};
export default Footer;

