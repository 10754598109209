import React, {useContext, useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import {Divider, Stack} from "@mui/material";
import UseCaseCard from "./use-case-card";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WindowIcon from '@mui/icons-material/Window';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import StarIcon from '@mui/icons-material/Star';
import {useNavigate} from "react-router-dom";
import Container from "@mui/material/Container";
import UseCaseModal from "./use-case-modal";
import {UserContext} from "../context/user.context";
import {OwnUseCasesContext} from "../context/use-cases/own-use-cases.context";
import {CompanyUseCasesContext} from "../context/use-cases/company-use-cases.context";
import {FavoriteUseCasesContext} from "../context/use-cases/favorite-use-cases.context";

const KEY_MAP = ['own', 'company', 'favorites'];

const MyContent = (props) => {
    const navigate = useNavigate();
    const {ownUseCases} = useContext(OwnUseCasesContext)
    const {companyUseCases} = useContext(CompanyUseCasesContext)
    const {favoriteUseCases} = useContext(FavoriteUseCasesContext)
    const [tabValue, setTabValue] = React.useState(0);

    const {user} = useContext(UserContext)
    const [modalEditable, setModalEditable] = useState(false)
    const [clickedUseCase, setClickedUseCase] = useState();
    const [openUseCaseForm, setOpenUseCaseForm] = useState(false);

    const openForm = () => {
        setOpenUseCaseForm(true);
    };
    const closeForm = () => {
        setOpenUseCaseForm(false);
    }

    useEffect(() => {
        if (props.contentFilter) {
            const index = KEY_MAP.findIndex((key) => key === props.contentFilter);
            if (index >= 0) {
                setTabValue(index);
            }
        }
    },[props.contentFilter]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        navigate(`/my-content/${KEY_MAP[newValue]}`, {replace: true});
    };

    const checkEditAuth = (val) => {
        val.createdFromUserID === user.id? setModalEditable(true): setModalEditable(false)
    }

    const checkTab = () => {
        switch(tabValue) {
            case 0:
                return ownUseCases
            case 1:
                return companyUseCases
            case 2:
                return favoriteUseCases
            default:
                console.log("No Tab")
        }
    }

    return (
        <Container maxWidth="xl">
            <Tabs value={tabValue} onChange={handleTabChange} sx={{mt: 5}}>
                <Tab icon={<PersonIcon />} iconPosition="start" label="Mein Bereich - Eigene" />
                <Tab icon={<BusinessIcon />} iconPosition="start" label="Mein Bereich - Strategion" />
                <Tab icon={<StarIcon />} iconPosition="start" label="Mein Bereich - Favoriten" />
            </Tabs>
            <Divider/>
            <Grid container spacing={2} sx={{mt:4}}>
                <Grid item xs={2}>
                    <Typography variant="subtitle1" color="text.primary" align="left" component="p">
                        {checkTab().length} Treffer
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Stack direction="row">
                        <Typography variant="subtitle1" color="text.primary" align="left" component="p">
                            Sortierung
                        </Typography>
                        <ArrowDropDownIcon/>
                    </Stack>
                </Grid>
                <Grid item xs={1} justifyContent="flex-end" alignItems="center">
                        <WindowIcon fontSize="small" color="blue"/>
                        <FormatListBulletedIcon fontSize="small" color="gray"/>
                </Grid>
            </Grid>
            <Divider variant="searchfield"/>
            <Grid sx={{my: 1}} container spacing={4}>
                {checkTab().map((val, key) => (
                    <Grid item key={val.id} xs={12} sm={5} md={4} lg={3}>
                        <UseCaseCard
                            key={val.id}
                            onClick={() => {
                                openForm()
                                setClickedUseCase(val)
                                checkEditAuth(val)
                            }}
                            heading={val.title}
                            description={val.description}
                            image={val.id}
                            favorite={val.favorite}
                            date={val.createdAt? val.createdAt: "Kein Datum"}
                            invitedUserIds={val.invitedUserIds}
                            invitedCompanyIds={val.invitedCompanyIds}
                            numberOfApprovedCompanies={val.numberOfApprovedCompanies}
                            approvedByCompanyIds={val.approvedByCompanyIds}
                            stateId={val.state}
                            useCase={val} />
                    </Grid>
                ))}
                <UseCaseModal open={openUseCaseForm} close={closeForm} data={clickedUseCase} editable={modalEditable} newUseCase={false}></UseCaseModal>
            </Grid>

        </Container>
    );
}

export default MyContent;
