import React, {useContext, useEffect} from "react";
import {useParams} from "react-router-dom";

import '../styles/app.css';
import Container from "@mui/material/Container";
import MyContent from "../components/my-content";
import {UserContext} from "../context/user.context";
import {OwnUseCasesContext} from "../context/use-cases/own-use-cases.context";
import {AuthContext} from "../context/auth.context";
import {CompanyUseCasesContext} from "../context/use-cases/company-use-cases.context";
import {FavoriteUseCasesContext} from "../context/use-cases/favorite-use-cases.context";
import {getOwnUseCases} from "../api/get-own-use-cases";
import {getCompanyUseCases} from "../api/get-company-use-cases";
import {getFavoriteUseCases} from "../api/get-favorite-use-cases";

const MyContentPage = () => {
    const params = useParams();

    const {user} = useContext(UserContext)
    const {setOwnUseCases} = useContext(OwnUseCasesContext)
    const {setCompanyUseCases} = useContext(CompanyUseCasesContext)
    const {setFavoriteUseCases} = useContext(FavoriteUseCasesContext)
    const {token} = useContext(AuthContext);

    useEffect(() => {

        if(Object.keys(user).length > 0) {
            getOwnUseCases(user.id, token, setOwnUseCases)
                .then(res => console.log(res))
                .catch(err => console.log(err))
        }


    }, [user])

    useEffect(() => {

        if(Object.keys(user).length > 0) {
            getCompanyUseCases(user.id, token, setCompanyUseCases)
                .then(res => console.log(res))
                .catch(err => console.log(err))
        }


    }, [user])

    useEffect(() => {

        if(Object.keys(user).length > 0) {
            getFavoriteUseCases(user.id, token, setFavoriteUseCases)
                .then(res => console.log(res))
                .catch(err => console.log(err))
        }


    }, [user])

    function getClassName(){
        let className = "background";

        if (user.firm_id === "5899") {
            className = "background_user1";
        } else if (user.firm_id === "2545") {
            className = "background_user2";
        }

        return className;
    }

    return (
        <Container maxWidth="xxl" sx={{py: 8}} className={getClassName()}>
            <MyContent contentFilter={params.contentFilter}></MyContent>
        </Container>
    );
}

export default MyContentPage;
