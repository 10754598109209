import * as React from 'react';
import {Route, Routes} from "react-router";
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ResponsiveAppBar from './components/responsive-app-bar';
import Footer from './pages/Footer';
import Home from './pages/Home';
import MyContentPage from "./pages/MyContentPage";
import UseCasePage from "./pages/UseCasePage";
import {useContext, useEffect} from "react";
import {AuthContext} from "./context/auth.context";
import {UserContext} from "./context/user.context";
import jwtDecode from "jwt-decode";
import axios from "axios";
import {checkUserTheme} from "./layouts/check-user-theme";
const BASE_URL = process.env.REACT_APP_BACKEND_URL_1
const App = () => {

    const {token} = useContext(AuthContext);
    const {user, setUser} = useContext(UserContext);

    const theme = createTheme(checkUserTheme(user));

    useEffect(() => {
        const decode = jwtDecode(token)
        axios.request({
            url: `${BASE_URL}/api/user/${decode.sub}`,
            method: 'get',
            headers: {
                'Authorization':`Bearer ${token}`,
            }
        }).then(res => {
            setUser(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [token, setUser])


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <ResponsiveAppBar/>
            <Routes>
                <Route path="/" element={<Home/>}></Route>
                <Route path="/use-cases" element={<UseCasePage/>}></Route>
                <Route path="/my-content" element={<MyContentPage/>}></Route>
                <Route path="/my-content/:contentFilter" element={<MyContentPage/>}></Route>
            </Routes>
            <Footer></Footer>
        </ThemeProvider>
    );
}

export default App;
