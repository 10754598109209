import {Controller, useFormContext} from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {ButtonBase, Switch, TextareaAutosize, TextField, Typography} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import CodeIcon from "@mui/icons-material/Code";
import PsychologyIcon from "@mui/icons-material/Psychology";
import React, {useContext, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import Editor from "@monaco-editor/react";
import {UserContext} from "../../../context/user.context";
import Button from "@mui/material/Button";

const GitHubSection = (props) => {
    const {control} = useFormContext();
    const { user } = useContext(UserContext);

    return (
        <>
            <Grid item xs={12} className="formGrids">
                <Controller control={control} name={`${props.form}.C${user.firm_id}.gitURL`}
                            render={({field}) => (
                                <TextField id="url" label="Url eingeben..." variant="outlined" fullWidth
                                           margin="normal"
                                           size="small" {...field}/>
                            )}
                />
            </Grid>
            <Grid item xs={12} className="formGrids">
                <Controller control={control} name={`${props.form}.C${user.firm_id}.gitToken`}
                            render={({field}) => (
                                <TextField id="url" label="Token eingeben..." variant="outlined" fullWidth
                                           margin="normal"
                                           size="small" {...field}/>
                            )}
                />
            </Grid>
        </>

    )
}

const CodeEditorSection = (props) => {
    const {control} = useFormContext();
    const { user } = useContext(UserContext);

    return (
        <Controller control={control} name={`${props.form}.C${user.firm_id}.code`}
                    render={({field}) => (
                        <Editor height="52vh" defaultLanguage="python" defaultValue="// some comment"
                                theme="vs-dark" options={{
                            minimap: false,
                            automaticLayout: true,}} {...field}/>
                    )}
        />
    )
}

const LowCodeSection = (props) => {

    const {control} = useFormContext();
    const { user } = useContext(UserContext);

    return (
        <>
            <Grid item xs={1} className="formGrids">
                <Tooltip title="Anzahl der Teilnehmer:Innen am Use-Case. -1 gleich ungebrenzt.">
                    <InfoIcon/>
                </Tooltip>
            </Grid>
            <Grid item xs={4} className="formGrids">
                <Typography variant="formLabels">Epochen</Typography>
            </Grid>
            <Grid item xs={7} className="formGrids">
                <Controller control={control} name={`${props.form}.C${user.firm_id}.epochen`}
                            render={({field}) => (
                                <TextField id="needed_user" variant="outlined" margin="normal"
                                           type="number" size="small" sx={{width: "20%"}}
                                           {...field}/>
                            )}
                />
            </Grid>
            <Grid item xs={1} className="formGrids">
                <Tooltip title="">
                    <InfoIcon/>
                </Tooltip>
            </Grid>
            <Grid item xs={4} className="formGrids">
                <Typography variant="formLabels">Batch Size</Typography>
            </Grid>
            <Grid item xs={7} className="formGrids">
                <Controller control={control} name={`${props.form}.C${user.firm_id}.batchSize`}
                            render={({field}) => (
                                <TextField id="needed_user" variant="outlined" margin="normal"
                                           type="number" size="small" sx={{width: "20%"}}
                                           {...field}/>
                            )}
                />
            </Grid>
            <Grid item xs={1} className="formGrids">
                <Tooltip title="">
                    <InfoIcon/>
                </Tooltip>
            </Grid>
            <Grid item xs={4} className="formGrids">
                <Typography variant="formLabels">Shuffle</Typography>
            </Grid>
            <Grid item xs={7} className="formGrids">
                <Controller control={control} name={`${props.form}.C${user.firm_id}.shuffle`}
                            render={({field}) => (
                                <Switch
                                    {...field}
                                    checked={field.value}
                                    onChange={(value) => field.onChange(value)}
                                />
                            )}
                />
            </Grid>
            <Grid item xs={1} className="formGrids">
                <Tooltip title="">
                    <InfoIcon/>
                </Tooltip>
            </Grid>
            <Grid item xs={4} className="formGrids">
                <Typography variant="formLabels">Validation Split (%)</Typography>
            </Grid>
            <Grid item xs={7} className="formGrids">
                <Controller control={control} name={`${props.form}.C${user.firm_id}.validationSplit`}
                            render={({field}) => (
                                <TextField id="needed_user" variant="outlined" margin="normal"
                                           type="number" size="small" sx={{width: "20%"}}
                                           {...field}/>
                            )}
                />
            </Grid>
            <Grid item xs={1} className="formGrids">
                <Tooltip title="">
                    <InfoIcon/>
                </Tooltip>
            </Grid>
            <Grid item xs={4} className="formGrids">
                <Typography variant="formLabels">Erweitertes Logging</Typography>
            </Grid>
            <Grid item xs={7} className="formGrids">
                <Controller control={control} name={`${props.form}.C${user.firm_id}.extendedLogging`}
                            render={({field}) => (
                                <Switch
                                    {...field}
                                    checked={field.value}
                                    onChange={(value) => field.onChange(value)}
                                />
                            )}
                />
            </Grid>
        </>


    )
}


function getStepContent(step, form) {

    switch (step) {
        case 0:
            return <CodeEditorSection form={form}/>;
        case 1:
            return <GitHubSection form={form}/>;
        case 2:
            return <LowCodeSection form={form}/>;
        default:
            return "unknown step";
    }
}

const FormContent = (props) => {

    const [tab, setTab] = useState(props.defaultTab ? props.defaultTab : 0);
    const defaultColorLowCode = props.showLowCode ? "black" : "disabled";

    return (
        <Box sx={{m: 3}}>
            <Grid container sx={{pl: '0px', pt: '8px', pr: '15px', pb: '5px', mr: '1'}}>
                {/* Zeile 1 */}
                <Grid item xs={4} className="formTab">
                    <ButtonBase onClick={() => setTab(0)}>
                        <CodeIcon color={tab === 0 ? "blue" : "black"}/>
                    </ButtonBase>
                </Grid>
                <Grid item xs={4} className="formTab">
                    <ButtonBase onClick={() => setTab(1)}>
                        <GitHubIcon color={tab === 1 ? "blue" : "black"}/>
                    </ButtonBase>
                </Grid>
                <Grid item xs={4} className="formTab" mb={0.5}>
                    {/*<ViewInArIcon/>*/}
                    <ButtonBase onClick={() => setTab(2)} disabled={!props.showLowCode}>
                        <PsychologyIcon color={tab === 2 ? "blue" : defaultColorLowCode}/>
                    </ButtonBase>
                </Grid>
                <Grid item xs={12} mb={4} sx={{marginBottom:0}}>
                    {/*<ViewInArIcon/>*/}
                    {props.showSuggestButton && <Button>Vorschlag einreichen</Button>}
                    {props.showDefaultButton && <Button>Default übernehmen</Button>}
                </Grid>
                {getStepContent(tab, props.form, props.showLowCode)}
                {/*
                    <Grid item xs={2} className="formGrids">
                    <Controller control={control} name="preprocessingPublic"
                                render={({field}) => (
                                    <Switch id="preprocessingPublic" defaultChecked/>)}
                    />
                </Grid>
                <Grid item xs={8} className="formGrids">
                    <Typography variant="formLabels">Öffentlich verfügbar</Typography>
                </Grid>
                */}
            </Grid>
        </Box>
    )
}

export default FormContent;
