import { createContext, useState } from 'react'

export const FavoriteUseCasesContext = createContext({
    favoriteUseCases: [],
    setFavoriteUseCases: () => null,
});

export const FavoriteUseCasesProvider = ({ children }) => {
    const [favoriteUseCases, setFavoriteUseCases] = useState([]);


    return <FavoriteUseCasesContext.Provider value={{favoriteUseCases, setFavoriteUseCases}}>{children}</FavoriteUseCasesContext.Provider>
}