import {Controller, useFormContext} from "react-hook-form";
import Box from "@mui/material/Box";
import {
    FormControl,
    FormControlLabel, Paper,
    Radio,
    RadioGroup,
    Switch, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import React, {useContext, useEffect, useState} from "react";
import CSVUpload from "../../csv-upload";
import {UserContext} from "../../../context/user.context";
import axios from "axios";
import {AuthContext} from "../../../context/auth.context";
const BACKEND_URL2 = process.env.REACT_APP_BACKEND_URL_2;
const BACKEND_URL3 = process.env.REACT_APP_BACKEND_URL_3;
const DataForm = (props) => {
    const {control} = useFormContext();
    const {user} = useContext(UserContext);
    const {token} = useContext(AuthContext);
    const [columns, setColumns] = useState([])
    const [fileName, setFileName] = useState("")
    const [useCaseId, setUseCaseID] = useState("")

    const handleChange = (event) => {
        axios.request({
            url: `${user.firm_id === "5899" ? BACKEND_URL2 : BACKEND_URL3}/upload/csv`,
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            data: {
                owner_id: user.id,
                use_case_id: useCaseId,
                file: event.target.files[0]
            }

        }).then(res => {
            setColumns(res.data)
            setFileName(event.target.files[0].name);
        }).catch(_ => {
            console.log("Fehler beim Hochladen der CSV Datei")
        })

    }

    const handleRemove = (event) => {
        setFileName("")
        setColumns([])

    }

    useEffect (() => {
        if(props.data !== undefined) {
            setUseCaseID(props.data.id)
            axios.request({
                url: `${user.firm_id === "5899" ? BACKEND_URL2 : BACKEND_URL3}/filescsv/${user.id}/${props.data.id}`,
                method: "get",
                mode: "cors",
                header: {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }
            }).then(res => {
                setColumns(res.data[0].columnNames)
                setFileName(res.data[0].name)
            }).catch(error => {
                if(error.response.status === 404) {

                }
            })
        } else {
            setUseCaseID(props.useCaseID)
        }

    }, [])


    return (
        <Box sx={{margin: 3}}>
            <Box textAlign="center" mb={3}>
                <Typography mb={2}>
                    Bitte laden Sie Ihre Trainingsdaten in Ihren privaten Datentresor hoch oder
                    geben Sie die Verbindungsdaten zur Datenbank an.
                </Typography>
            </Box>
            <Box sx={{mt: 0, width: "inherit", mb: 3}}>
                <CSVUpload handleChange={handleChange} columns={columns} name={fileName} label="Datensatz hochladen" height="50px" handleRemove={handleRemove}/>
            </Box>
            <Box textAlign="center" mb={3}>
                <Typography>
                    Hinweis: Trainingsdaten und Verdindungsdaten werden
                    verschlüsselt in Ihrem lokalen privaten Tresor gespeichert.
                </Typography>
            </Box>
            <Grid container sx={{mt: 0, width: "inherit", mb: 5}}>
                {/* Zeile 2 */}
                <Grid item xs={1} className="formGrids">
                    <Tooltip title="">
                        <InfoIcon/>
                    </Tooltip>
                </Grid>
                <Grid item xs={11} className="formGrids">
                    <Typography variant="formLabels">Anonymisierung</Typography>
                </Grid>
                <Grid item xs={1} className="formGrids"></Grid>
                <Grid item xs={11} className="formGridsRGrp">
                    <Controller name="anonymizing" defaultValue="" control={control}
                                render={({field}) => (
                                    <FormControl>
                                        <RadioGroup required {...field}
                                                    onChange={(event, value) => field.onChange(value)}
                                                    value={field.value}>
                                            <FormControlLabel label="KEINE Anonymisierung" value="noanonym"
                                                              control={<Radio/>}/>
                                            <FormControlLabel label="Anonymisierung" value="anonym"
                                                              control={<Radio/>}/>
                                            <FormControlLabel label="Pseudonymisierung" value="pseudonym"
                                                              control={<Radio/>}/>
                                        </RadioGroup>
                                    </FormControl>
                                )}>
                    </Controller>
                </Grid>


            </Grid >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Spaltenname</TableCell>
                            <TableCell align="center">Bemerkung</TableCell>
                            <TableCell align="center">Beispieldatensatz</TableCell>
                            <TableCell align="center">Löschen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {columns.map((column, index) =>
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell width={250} align="center">
                                    {column}
                                </TableCell>
                                <TableCell align="center"><TextField multiline rows={5}></TextField></TableCell>
                                <TableCell align="center"><TextField multiline rows={5}></TextField></TableCell>
                                <TableCell align="center"><Switch/></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default DataForm
