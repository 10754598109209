import React, {Fragment} from 'react';
import Box from "@mui/material/Box";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {Controller, useFormContext} from "react-hook-form";
import {Typography} from "@mui/material";

const CSVUpload = (props) => {

    const {control} = useFormContext()

    return (
        <Box sx={{height: props.height ? props.height : 200,
            backgroundColor: 'gray.uploadFieldBg',
            display:'flex', justifyContent:'center', alignItems:'center', width: '100%'}}>
            {props.name === "" &&
                <Fragment>
                    <label htmlFor="file-upload" className="custom-file-upload" id="file-upload-label">
                        {props.label ? props.label : 'Vorschaubild hochladen'}
                    </label>
                    <FileUploadIcon />
                </Fragment>
            }
            <Controller control={control} name="csvFile" render={({field}) => (
                <input
                    id="file-upload"
                    type="file"
                    onChange={(e) => {
                        props.handleChange(e)
                        field.onChange(e.target.files[0])
                    }}
                    style={{display: 'none'}}/>
            )} />

            {props.name !== "" && <Typography onClick={props.handleRemove} >{props.name}</Typography>}
        </Box>
    );
}

export default CSVUpload;