import React, {useState} from 'react';
import Modal from "@mui/material/Modal";
import LinearStepper from "./use-case-form/linear-stepper";
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    Stack,
    Avatar,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import {blue} from '@mui/material/colors';

const emails = [
    "Dominik Hubertus",
    "Florian Remark",
    "Henrik Kortum",
    "Jonas Rebstadt",
    "Jonah Schlie",
    "Philipp Fukas"
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60vw",
    height: "90vh",
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '5px',
    boxShadow: 24,
    paddingTop: 0,
};

function SimpleDialog(props) {
    const {onClose, selectedValue, open} = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Person(en) zum Bearbeiten einladen</DialogTitle>
            <List sx={{pt: 0}}>
                {emails.map((email, index) => (
                    <ListItem disableGutters key={index}>
                        <ListItemButton onClick={() => handleListItemClick(email)} key={email}>
                            <ListItemAvatar>
                                <Avatar sx={{bgcolor: blue[100], color: blue[600]}}>
                                    <PersonIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={email}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

const UseCaseModal = (props) => {
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClick={e => e.stopPropagation()}
                BackdropProps={{
                    style: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}
                }}
            >
                <Grid sx={style}>
                    <Stack direction="row" bgcolor="black.main" height="42px" borderRadius="5px 5px 0px 0px">
                        <Grid container spacing={0} sx={{pl: '15px', pt: '8px', pr: '15px', pb: '5px', mr: '1'}}>
                            <Grid item xs={10} justifyContent="left" display="flex" sx={{pr: 2}}>
                                {props.newUseCase === true ?
                                    <Typography variant="modalDialog">Neuen Use-Case erstellen</Typography> :
                                    <Typography variant="modalDialog">Use-Case bearbeiten</Typography>
                                }
                            </Grid>
                            <Grid item xs={1} justifyContent="right" display="flex" sx={{pt: '1px'}}>
                                <IconButton onClick={handleClickOpen} sx={{p: 0}}>
                                    <AccountCircleIcon color="white"/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={1} justifyContent="right" display="flex" sx={{pt: '1px'}}>
                                <IconButton onClick={props.close} sx={{p: 0}}>
                                    <CloseIcon color="white"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Stack>
                    <LinearStepper onClose={props.close} data={props.newUseCase === false ? props.data : undefined}
                                   newUseCase={props.newUseCase} editable={props.editable}></LinearStepper>
                </Grid>
            </Modal>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </>
    );
}
export default UseCaseModal;
