import React, {Fragment, useState} from 'react';
import Box from "@mui/material/Box";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {Controller, useFormContext} from "react-hook-form";
import {Typography} from "@mui/material";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_1;

const ImageUpload = (props) => {

    const {control} = useFormContext()

    const [preImage, setPreImage] = useState("")
    const [fileIsUploaded, setFileIsUploaded] = useState(props.data !== undefined?true: false);
    const [newImage, setnewImage] = useState(false)


    const handleChange = (event) => {
        setnewImage(true)
        setFileIsUploaded(true);
        setPreImage(URL.createObjectURL(event.target.files[0]));
    }

    const handleRemove = (event) => {
        setFileIsUploaded(false);
        setPreImage("")
        setnewImage(true)
    }

    return (
        <Box sx={{height: props.height ? props.height : 200,
            backgroundColor: fileIsUploaded ? 'white.main' : 'gray.uploadFieldBg',
            display:'flex', justifyContent:'center', alignItems:'center', width: '100%'}}>
            {!fileIsUploaded && ! props?.value &&
                <Fragment>
                    <label htmlFor="file-upload" className="custom-file-upload" id="file-upload-label">
                        {props.label ? props.label : 'Vorschaubild hochladen'}
                    </label>
                    <Typography variant="formLabelsRequired">*</Typography>
                    <FileUploadIcon />
                </Fragment>
            }
            <Controller control={control} name="file" render={({field}) => (
                <input
                    id="file-upload"
                    type="file"
                    disabled={!props.editable}
                    onChange={(e) => {
                        handleChange(e);
                        field.onChange(e.target.files[0]);
                        if (props.onImageChange) props.onImageChange(true);
                    }}
                    style={{display: 'none'}}/>
            )} />

            {props.data !== undefined && !newImage && fileIsUploaded &&
                <img src={BACKEND_URL+'/api/files/images/'+ props.data.id} height="200px;" onClick={handleRemove} alt="Vorschaubild"/>
            }

            {newImage && preImage !== "" && fileIsUploaded &&
                <img src={preImage} height="200px;" onClick={handleRemove} alt="Vorschaubild"/>
            }

            { !newImage && preImage === "" && !fileIsUploaded && props?.value &&
                <img src={URL.createObjectURL(props.value)} height="200px;" onClick={handleRemove} alt="Vorschaubild"/>
            }

        </Box>
    );
}

export default ImageUpload;
