import axios from "axios";
const BASE_URL = process.env.REACT_APP_BACKEND_URL_1
export async function getAllUseCases(id, token, setAllUseCases) {

        axios.request({
            url: `${BASE_URL}/api/user/${id}/use-cases/approved-invited`,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        }).then(res => {

            setAllUseCases(res.data)

        }).catch(_ => {

            setAllUseCases([])
        })
}