import { createContext, useState } from 'react'

export const OwnUseCasesContext = createContext({
    ownUseCases: [],
    setOwnUseCases: () => null,
});

export const OwnUseCasesProvider = ({ children }) => {
    const [ownUseCases, setOwnUseCases] = useState([]);


    return <OwnUseCasesContext.Provider value={{ownUseCases, setOwnUseCases}}>{children}</OwnUseCasesContext.Provider>
}