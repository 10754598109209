import React from "react";
import FormContent from "./form-content";

const SpecificationFrom = (props) => {


    return(
        <FormContent data={props.data} form="specification"
                     showLowCode={false} showSuggestButton={true} showDefaultButton={false}
                     defaultTab={props.defaultTab}/>
    )
}

export default SpecificationFrom
