import {Controller, useFormContext} from "react-hook-form";
import Box from "@mui/material/Box";
import ImageUpload from "../../image-upload";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import React, {useContext} from "react";
import {UserContext} from "../../../context/user.context";

const BasicForm = (props) => {
    const {control} = useFormContext();
    const {user} = useContext(UserContext)

    const users = [
        {
            email: "Dominik Hubertus",
            id: "a77d1974-a860-43e4-82c2-161ab9c6473f"
        },
        {
            email: "Florian Remark",
            id: "a77d1975-a860-43e4-82c2-161ab9c6473f"
        },
        {
            email: "Henrik Kortum",
            id: "b3229fe1-3abe-4021-998b-71830e175473"
        },
        {
            email: "Jonas Rebstadt",
            id: "a77d1976-a860-43e4-82c2-161ab9c6473f"
        },
        {
            email: "Jonah Schlie",
            id: "a77d1977-a860-43e4-82c2-161ab9c6473f"
        },
        {
            email: "Philipp Fukas",
            id: "a77d1978-a860-43e4-82c2-161ab9c6473f"
        }]
    const companies = [
        {
            name: "Bank 1",
            id: "2545"
        },
        {
            name: "Bank 2",
            id: "5899"
        },
        {
            name: "Bank 3",
            id: "1982"
        },
    ]

    return (
        <Box sx={{m: 3}}>
            {/*<Typography>Vorschaubild hochladen</Typography>*/}
            <Controller control={control} name="file"
                        render={({field}) => (
                            <ImageUpload required editable={props.editable} data={props.data}
                                         onImageChange={props.onImageChange} {...field}/>
                        )}
            />
            <Typography variant="formLabels">Titel</Typography>
            <Typography variant="formLabelsRequired">*</Typography>
            <Controller control={control} name="title"
                        render={({field}) => (
                            <TextField required id="heading" variant="outlined" fullWidth margin="normal"
                                       size="small" disabled={!props.editable} {...field}/>
                        )}
            />
            <Typography variant="formLabels">Beschreibung</Typography>
            <Typography variant="formLabelsRequired">*</Typography>
            <Controller control={control} name="description"
                        render={({field}) => (
                            <TextareaAutosize id="description" variant="outlined" margin="normal" size="small"
                                              disabled={!props.editable} style={{width: '100%', height: '20vh'}}
                                              maxRows={10} {...field} required/>
                        )}
            />
            <Grid container sx={{pl: '0px', pt: '8px', pr: '15px', pb: '5px', mr: '1'}}>
                {/* Line 1 */}
                <Grid item xs={1} className="formGrids">
                    <Tooltip title="Anzahl der Teilnehmer:Innen am Use-Case. -1 gleich ungebrenzt.">
                        <InfoIcon/>
                    </Tooltip>
                </Grid>
                <Grid item xs={4} className="formGrids">
                    <Typography variant="formLabels">Mindestanzahl Teilnehmer:Innen</Typography>
                    <Typography variant="formLabelsRequired">*</Typography>
                </Grid>
                <Grid item xs={7} className="formGrids">
                    <Controller control={control} name="participants"
                                render={({field}) => (
                                    <TextField required id="needed_user" variant="outlined" margin="normal"
                                               type="number" size="small" sx={{width: "20%"}} disabled={!props.editable}
                                               {...field}/>
                                )}
                    />
                </Grid>
                {/* Line 2 */}
                <Grid item xs={1} className="formGrids">
                    <Tooltip title="Teilnahmeberechtigung am Use-Case">
                        <InfoIcon/>
                    </Tooltip>
                </Grid>
                <Grid item xs={11} className="formGrids">
                    <Typography variant="formLabels">Teilnahmeberechtigung</Typography>
                    <Typography variant="formLabelsRequired">*</Typography>
                </Grid>
                <Grid item xs={1} className="formGrids"></Grid>
                <Grid item xs={11} className="formGridsRGrp">
                    <Controller name="visibleForAll" control={control} render={({field}) => (
                        <FormControl>
                            <RadioGroup {...field} onChange={(event, value) => field.onChange(value)}
                                        value={field.value}>
                                <FormControlLabel label="Alle" value={true} disabled={!props.editable}
                                                  control={<Radio/>}/>
                                <FormControlLabel
                                    label="Nur auf Einladung"
                                    value={false}
                                    disabled={!props.editable}
                                    control={<Radio/>}
                                />
                            </RadioGroup>
                            {(field.value === "false" || !field.value ) && (
                                <>
                                    <Grid item xs={12} pl={5} pt={3}>
                                        <Typography>Benutzer</Typography>
                                        <Controller
                                            control={control}
                                            name="invitedUserIds"
                                            render={({field}) => (
                                                <FormGroup>
                                                    {users
                                                        .filter((item) => (user.id !== item.id))
                                                        .map((item) => (
                                                        <FormControlLabel
                                                            key={item.id}
                                                            control={
                                                                <Checkbox disabled={!props.editable}
                                                                    checked={field.value && field.value.includes(item.id)}
                                                                    onChange={(e) => {
                                                                        const selectedOptions = field.value ? [...field.value] : [];
                                                                        if (e.target.checked) {
                                                                            selectedOptions.push(item.id);
                                                                        } else {
                                                                            const index = selectedOptions.indexOf(item.id);
                                                                            if (index > -1) {
                                                                                selectedOptions.splice(index, 1);
                                                                            }
                                                                        }
                                                                        field.onChange(selectedOptions);
                                                                    }}
                                                                    value={item.email}
                                                                />
                                                            }
                                                            label={item.email}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} pl={5} pt={3}>
                                        <Typography>Firmen</Typography>
                                        <Controller
                                            control={control}
                                            name="invitedCompanyIds"
                                            render={({field}) => (
                                                <FormGroup>
                                                    {companies
                                                        .filter((item) => (user.firm_id !== item.id))
                                                        .map((item) => (
                                                        <FormControlLabel
                                                            key={item.id}
                                                            control={
                                                                <Checkbox disabled={!props.editable}
                                                                    checked={field.value && field.value.includes(item.id)}
                                                                    onChange={(e) => {
                                                                        const selectedOptions = field.value ? [...field.value] : [];
                                                                        if (e.target.checked) {
                                                                            selectedOptions.push(item.id);
                                                                        } else {
                                                                            const index = selectedOptions.indexOf(item.id);
                                                                            if (index > -1) {
                                                                                selectedOptions.splice(index, 1);
                                                                            }
                                                                        }
                                                                        field.onChange(selectedOptions);
                                                                    }}
                                                                    value={item.name}
                                                                />
                                                            }
                                                            label={item.name}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            )}
                                        />
                                    </Grid>
                                </>
                            )}
                        </FormControl>
                    )}>
                    </Controller>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BasicForm;
