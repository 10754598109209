import axios from "axios";
import {getAllUseCases} from "./get-all-use-cases";
import {getOwnUseCases} from "./get-own-use-cases";
import {getCompanyUseCases} from "./get-company-use-cases";
import {getFavoriteUseCases} from "./get-favorite-use-cases";
const BASE_URL = process.env.REACT_APP_BACKEND_URL_1
export async function updateUseCase(formData, token, user_id, use_case_id, image_changed=false, setAllUseCases, setOwnUseCases, setCompanyUseCases, setFavoriteUseCases) {

    if (image_changed) {
        let formDataPhotoUpdate = {
            file: formData.file
        };
        let response = await axios.request({
            url: `${BASE_URL}/api/files/update/photo/${use_case_id}/${user_id}`,
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            data: formData
        });
        console.log(response);
        if (response.status === 200) {
            console.log("Photo updated")
            getAllUseCases(user_id, token, setAllUseCases)
                .then( (res) => {
                    return getOwnUseCases(user_id, token, setOwnUseCases)
                })
                .then( (res) => {
                    return getCompanyUseCases(user_id, token, setCompanyUseCases)
                })
                .then( (res) => {
                    return getFavoriteUseCases(user_id, token, setFavoriteUseCases)
                })
                .catch(error => console.log(error))
        } else {
            throw new Error(`Photo not updated - Status Code ${response.status}`);
        }
    }
    let req_data = {...formData};
    if (req_data.file || req_data.file?.length === 0) delete req_data.file;
    axios.request({
        url: `${BASE_URL}/api/files/update/${use_case_id}/${user_id}`,
        method: 'post',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: req_data
    }).then(_ => {
        getAllUseCases(user_id, token, setAllUseCases)
            .then( (res) => {
                return getOwnUseCases(user_id, token, setOwnUseCases)
            })
            .then( (res) => {
                return getCompanyUseCases(user_id, token, setCompanyUseCases)
            })
            .then( (res) => {
                return getFavoriteUseCases(user_id, token, setFavoriteUseCases)
            })
            .catch(error => console.log(error))
        console.log("Use Case updated")
    }).catch(_ => {
        console.log("Error updating use case");
    })

}
