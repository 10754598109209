import axios from "axios";
const BASE_URL = process.env.REACT_APP_BACKEND_URL_1
export async function getFavoriteUseCases(id, token, setFavoriteUseCases) {

    axios.request({
        url: `${BASE_URL}/api/user/${id}/use-cases/favorite`,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then(res => {
        setFavoriteUseCases(res.data);
    }).catch(_ => {
        setFavoriteUseCases([]);
        console.log(_);
    })

}