import axios from "axios";
const BASE_URL = process.env.REACT_APP_BACKEND_URL_1
export async function getOwnUseCases(id, token, setOwnUseCases) {

    axios.request({
        url: `${BASE_URL}/api/user/${id}/use-cases/owned`,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then(res => {
        setOwnUseCases(res.data);
    }).catch(_ => {
        setOwnUseCases([]);
    })

}