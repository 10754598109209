import React, {useContext} from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {AllUseCasesProvider} from "./context/use-cases/all-use-cases.context";
import {MyContextProvider} from "./context/my-content.context";
import {AuthContext, AuthProvider} from "./context/auth.context";
import Login from "./Login";
import {UserProvider} from "./context/user.context";
import {OwnUseCasesProvider} from "./context/use-cases/own-use-cases.context";
import {CompanyUseCasesProvider} from "./context/use-cases/company-use-cases.context";
import {FavoriteUseCasesProvider} from "./context/use-cases/favorite-use-cases.context";

/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);*/

const CheckAuthentication = () => {
    const {isAuthenticated} = useContext(AuthContext);
    let isAuth = isAuthenticated();
    return isAuth ? <App/>: <Login/>;
}

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <UserProvider>
                <AllUseCasesProvider>
                    <OwnUseCasesProvider>
                        <CompanyUseCasesProvider>
                            <FavoriteUseCasesProvider>
                                <MyContextProvider>
                                    <BrowserRouter>
                                        <CheckAuthentication/>
                                    </BrowserRouter>
                                </MyContextProvider>
                            </FavoriteUseCasesProvider>
                        </CompanyUseCasesProvider>
                    </OwnUseCasesProvider>
                </AllUseCasesProvider>
            </UserProvider>
        </AuthProvider>
    </React.StrictMode>
);

