import axios from "axios";
const BASE_URL = process.env.REACT_APP_BACKEND_URL_1
export async function getCompanyUseCases(id, token, setCompanyUseCases) {

    axios.request({
        url: `${BASE_URL}/api/user/${id}/use-cases/firm`,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then(res => {
        setCompanyUseCases(res.data);
    }).catch(_ => {
        setCompanyUseCases([]);
        console.log(_);
    })

}