import {createContext, useEffect, useState} from "react";

export const AuthContext = createContext({
    token: '',
    setToken: () => null,
    isAuthenticated: () => null,
    setAuthentication: () => null,
    logout: () => null
});

export const AuthProvider = ({children}) => {
    const [authenticated, setAuthentication] = useState(false)
    const [token, setToken] = useState('')

    useEffect(() => {

        if (localStorage.getItem('token') && localStorage.getItem('expirationDate')) {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate > new Date()) {
                console.debug("Token found");
                setAuthentication(true);
                setToken(localStorage.getItem('token'));
            } else {
                console.debug("Token expired");
                localStorage.removeItem('token');
                localStorage.removeItem('expirationDate');
                logoutHandler();
            }
        }

    },[]);

    useEffect(() => {
        let timer = null;
        if (authenticated) {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            const now = new Date();
            const timeout = expirationDate - now;
            if (timeout > 0) {
                timer = setTimeout(() => {
                    console.log("TIME OUT");
                    logoutHandler();
                }, timeout);
            }
        } else {
            if (timer)  clearTimeout(timer);
        }

    }, [authenticated]);

    function isAuthenticatedHandler() {
        return authenticated;
    }

    function setTokenHandler(token) {
        localStorage.setItem('token', token);
        const expirationDate = new Date();
        expirationDate.setMinutes(expirationDate.getMinutes() + 15);
        localStorage.setItem('expirationDate', expirationDate);
        setToken(token);
    }

    function logoutHandler() {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
        setAuthentication(false);
        setToken('');
    }

    const value = {
        isAuthenticated: isAuthenticatedHandler,
        setAuthentication,
        token,
        setToken: setTokenHandler,
        logout: logoutHandler
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

}
