import Container from "@mui/material/Container";
import React, {useContext, useEffect} from "react";

import UseCase from "../components/use-case";

import '../styles/app.css';
import {UserContext} from "../context/user.context";
import {AuthContext} from "../context/auth.context";
import {AllUseCasesContext} from "../context/use-cases/all-use-cases.context";
import axios from "axios";
import {getAllUseCases} from "../api/get-all-use-cases";

const UseCasePage = () => {

    const {user} = useContext(UserContext);
    const {token} = useContext(AuthContext);
    const {setAllUseCases} = useContext(AllUseCasesContext)

    useEffect(() => {

        if(Object.keys(user).length > 0) {
            getAllUseCases(user.id, token, setAllUseCases)
                .then(res => console.log(res))
                    .catch(err => console.log(err));
        }


    }, [user])

    function getClassName(){
        let className = "background";

        if (user.firm_id === "5899") {
            className = "background_user1";
        } else if (user.firm_id === "2545") {
            className = "background_user2";
        }

        return className;
    }

    return (
        <Container maxWidth="xxl" sx={{py: 8}} className={getClassName()}>
            <UseCase></UseCase>
        </Container>
    );
}

export default UseCasePage;
