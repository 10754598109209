import {useContext, useState} from "react";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import {useLocation, Link as RouterLink, useNavigate} from 'react-router-dom';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import UseCaseModal from "./use-case-modal";
import {ButtonBase, Menu, MenuItem} from "@mui/material";
import WindowIcon from "@mui/icons-material/Window";
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import StarIcon from '@mui/icons-material/Star';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {AuthContext} from "../context/auth.context";

import {AppBarUserLogo} from "../layouts/check-user-content";

import {UserContext} from "../context/user.context";


const pages = [
    {url: 'use-cases', title: 'Use Cases'},
    {url: 'my-content', title: 'Mein Bereich'}
];
//const ITEM_HEIGHT = 48;

const ResponsiveAppBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElProfile, setAnchorElProfile] = useState(null);
    const [openUseCaseForm, setOpenUseCaseForm] = useState(false);
    const openMenu = Boolean(anchorEl);
    const {setAuthentication, logout} = useContext(AuthContext);
    const {user} = useContext(UserContext)
    const openProfileMenu = Boolean(anchorElProfile)

    const openForm = () => {
        setOpenUseCaseForm(true);
    };
    const closeForm = () => {
        setOpenUseCaseForm(false);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClick = (event) => {
        setAnchorElProfile(event.currentTarget);
    };

    const handleLogout = () => {
        logout();
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseProfile = () => {
        setAnchorElProfile(null);
    };

    const handleMItemSelect = (url) => {
        navigate(url);
        handleClose();
    };

    return (
        <AppBar position="static" sx={{zIndex: 50}}>
            <Container maxWidth="xxl">
                <Toolbar disableGutters sx={{height: 10, justifyContent: 'space-between'}}>
                    <Box display="flex">
                        {/* Logo and application name */}
                        <ButtonBase onClick={handleMItemSelect.bind(null, "/")}>
                            <Typography variant="appBarLogo" noWrap component="a"
                                        sx={{
                                            mr: 2,
                                            display: {xs: 'none', md: 'flex'}
                                        }}
                            >
                                FL
                            </Typography>
                        </ButtonBase>
                        <Button variant="bigfont" id="basic-button" disableRipple
                                aria-controls={openMenu ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                                onClick={handleClick}
                        >
                            Federated Learning
                        </Button>
                        {/* Menu Items */}
                        <Menu id="long-menu" anchorEl={anchorEl} open={openMenu} onClose={handleClose}
                              PaperProps={{
                                  style: {
                                      width: '40ch',
                                      background: 'rgba(255, 255, 255, 0.80)',
                                  },
                              }}
                        >
                            <MenuItem key="1" onClick={handleMItemSelect.bind(null, "use-cases")}>
                                <WindowIcon fontSize="large" color="primary"/>
                                <Typography variant="menu" sx={{ml: 1}}>Use Cases</Typography>
                            </MenuItem>
                            <MenuItem key="2" onClick={handleMItemSelect.bind(null, "my-content/own")}>
                                <PersonIcon fontSize="large" color="primary"/>
                                <Typography variant="menu" sx={{ml: 1}}>Mein Bereich - Eigene</Typography>
                            </MenuItem>
                            <MenuItem key="3" onClick={handleMItemSelect.bind(null, "my-content/company")}>
                                <BusinessIcon fontSize="large" color="primary"/>
                                <Typography variant="menu" sx={{ml: 1}}>Mein Bereich - Strategion</Typography>
                            </MenuItem>
                            <MenuItem key="4" onClick={handleMItemSelect.bind(null, "my-content/favorites")}>
                                <StarIcon fontSize="large" color="primary"/>
                                <Typography variant="menu" sx={{ml: 1}}>Mein Bereich - Favoriten</Typography>
                            </MenuItem>
                            <MenuItem key="5" onClick={ () => {
                                openForm()
                                handleClose()
                            }}>
                                <AddIcon fontSize="large" color="primary"/>
                                <Typography variant="menu" sx={{ml: 1}}>Use-Case erstellen</Typography>
                            </MenuItem>
                        </Menu>

                        {/* Navigation Items */}
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            {pages.map((page) => (
                                <Button key={page.url} color={`${location.pathname === `/${page.url}` ? 'primary' : 'black'}`}
                                        onClick={null} disableRipple
                                        sx={{
                                            my: 2, display: 'block',
                                            fontWeight: `${location.pathname === `/${page.url}` ? 'bold' : 'normal'}`
                                        }}>
                                    <Link to={`/${page.url}`} color='inherit' component={RouterLink}
                                          style={{textDecoration: 'none'}}>
                                        {page.title}
                                    </Link>
                                </Button>
                            ))}
                            <IconButton aria-label="add" onClick={openForm} color="black" disableRipple>
                                <AddIcon/>
                                <UseCaseModal open={openUseCaseForm} close={closeForm} newUseCase={true} editable={true}></UseCaseModal>
                            </IconButton>
                        </Box>
                    </Box>
                    <AppBarUserLogo/>
                    {/* Profile */}
                    <Box sx={{display: 'flex'}}>
                        <Button onClick={null} disableRipple>
                            <Badge badgeContent={4} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                   color="black"
                                   sx={{alignItems: 'center', mr: '2em'}}>
                                <MailIcon size="large" color="primary"/>
                            </Badge>
                        </Button>
                        <Typography variant="appBarText"
                                    sx={{
                                        alignItems: 'center',
                                        display: {xs: 'none', md: 'flex'},
                                        mr: '2rem'
                                    }}>{user.email}</Typography>
                        <Tooltip title="Logout">
                            <IconButton aria-label='logout' onClick={handleLogout}>
                                <LogoutIcon
                                    color="primary"
                                    sx={{
                                        display: {xs: 'none', md: 'flex'},
                                    }}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Profil">
                            <IconButton aria-label='Profile' onClick={handleProfileClick}>
                                <PersonOutlineIcon
                                    color="primary"
                                    sx={{
                                        display: {xs: 'flex', md: 'none'},
                                    }}/>
                            </IconButton>
                        </Tooltip>
                        <Menu sx={{mt: 1.5}}id="long-menu" anchorEl={anchorElProfile} open={openProfileMenu} onClose={handleCloseProfile}
                              PaperProps={{
                                  style: {
                                      width: '20ch',
                                      background: 'rgba(255, 255, 255, 0.80)',
                                  },
                              }}
                        >
                            <MenuItem key="1">
                                <MailOutlineIcon fontSize="large" color="primary"/>
                                <Typography variant="menu" sx={{ml: 1}}>{user.email}</Typography>
                            </MenuItem>
                            <MenuItem key="2" onClick={() => {
                                handleLogout()
                                handleCloseProfile()
                            }}>
                                <LogoutIcon fontSize="large" color="primary"/>
                                <Typography variant="menu" sx={{ml: 1}}>Abmelden</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
