import React, {useContext} from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import '../styles/app.css';
import {UserContext} from "../context/user.context";

const Home = () => {

    const {user} = useContext(UserContext)

    function getClassName(){
        let className = "background";

        if (user.firm_id === "5899") {
            className = "background_user1";
        } else if (user.firm_id === "2545") {
            className = "background_user2";
        }

        return className;
    }

    return (
        <Container maxWidth="xxl" sx={{py: 8}} className={getClassName()}>
            <Typography component="h1" variant="h2" align="center" color="text.primary"
              gutterBottom sx={{mt: 20}}
            >
                Willkommen auf der
            </Typography>
            <Typography component="h1" variant="h2" align="center" color="text.primary"
                        gutterBottom
            >
                Federated Learning Plattform
            </Typography>
            <Typography component="h1" variant="h2" align="center" color="text.primary"
                        gutterBottom sx={{mb: 20}}
            >
                der Strategion GmbH
            </Typography>
          </Container>
    );
}

export default Home;
