import axios from "axios";

const BASE_URL_server = process.env.REACT_APP_BASE_URL_server
const BASE_URL1 = process.env.REACT_APP_BASE_URL1
const BASE_URL2 = process.env.REACT_APP_BASE_URL2

async function startServer(numOfRounds = 5) {

    const requestBody = {
        "operationName": "LaunchPipelineExecution",
        "variables":
            {
                "executionParams":
                    {
                        "runConfigData": "ops:\n  load_processed_data:\n    config:\n      dataset: \"data/hmeq.csv\"\n  start_flower_server:\n    config:\n      num_rounds: "+ numOfRounds,
                        "selector":
                            {
                                "repositoryName": "__repository__flower_federated_learning_pipeline",
                                "repositoryLocationName": "server.py",
                                "pipelineName": "flower_federated_learning_pipeline"
                            },
                        "mode": "default",
                        "executionMetadata":
                            {
                                "tags":
                                    [
                                        {
                                            "key": "dagster/solid_selection",
                                            "value": "*"
                                        }
                                    ]
                            }
                    }
            },
        "query": "mutation LaunchPipelineExecution($executionParams: ExecutionParams!) {\n  launchPipelineExecution(executionParams: $executionParams) {\n    ... on LaunchRunSuccess {\n      run {\n        id\n        pipelineName\n        __typename\n      }\n      __typename\n    }\n    ... on PipelineNotFoundError {\n      message\n      __typename\n    }\n    ... on InvalidSubsetError {\n      message\n      __typename\n    }\n    ... on RunConfigValidationInvalid {\n      errors {\n        message\n        __typename\n      }\n      __typename\n    }\n    ...PythonErrorFragment\n    __typename\n  }\n}\n\nfragment PythonErrorFragment on PythonError {\n  message\n  stack\n  errorChain {\n    ...PythonErrorChain\n    __typename\n  }\n  __typename\n}\n\nfragment PythonErrorChain on ErrorChainLink {\n  isExplicitLink\n  error {\n    message\n    stack\n    __typename\n  }\n  __typename\n}"
    };
    return await callDagster(BASE_URL_server, requestBody);
}

async function startClient(id) {
    let port = 3005 + id;
    const requestBody = {
        "operationName": "LaunchPipelineExecution",
        "variables":
            {
                "executionParams":
                    {
                        "runConfigData": "ops:\n  load_processed_data:\n    config:\n      dataset: data/hmeq.csv\n  start_flower_client:\n    config:\n      id: "+ id,
                        "selector":
                            {
                                "repositoryName": "__repository__flower_federated_learning_pipeline",
                                "repositoryLocationName": "client.py",
                                "pipelineName": "flower_federated_learning_pipeline"
                            },
                        "mode": "default",
                        "executionMetadata":
                            {
                                "tags":
                                    [
                                        {
                                            "key": "dagster/solid_selection",
                                            "value": "*"
                                        }
                                    ]
                            }
                    }
            },
        "query": "mutation LaunchPipelineExecution($executionParams: ExecutionParams!) {\n  launchPipelineExecution(executionParams: $executionParams) {\n    ... on LaunchRunSuccess {\n      run {\n        id\n        pipelineName\n        __typename\n      }\n      __typename\n    }\n    ... on PipelineNotFoundError {\n      message\n      __typename\n    }\n    ... on InvalidSubsetError {\n      message\n      __typename\n    }\n    ... on RunConfigValidationInvalid {\n      errors {\n        message\n        __typename\n      }\n      __typename\n    }\n    ...PythonErrorFragment\n    __typename\n  }\n}\n\nfragment PythonErrorFragment on PythonError {\n  message\n  stack\n  errorChain {\n    ...PythonErrorChain\n    __typename\n  }\n  __typename\n}\n\nfragment PythonErrorChain on ErrorChainLink {\n  isExplicitLink\n  error {\n    message\n    stack\n    __typename\n  }\n  __typename\n}"
    };

    return await callDagster(port === 3006 ? BASE_URL1 : BASE_URL2 , requestBody);
}

const BASIC_AUTH_USERNAME = process.env.REACT_APP_BASIC_AUTH_USERNAME;
const BASIC_AUTH_PASSWORD = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
async function callDagster(server, requestBody) {
        const authValue = `Basic ${btoa(`${BASIC_AUTH_USERNAME}:${BASIC_AUTH_PASSWORD}`)}`;
    return await axios.request({
        url: `${server}/graphql`,
        method: 'post',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization': authValue
        },
        data: requestBody
    });
}

export { startServer, startClient };
