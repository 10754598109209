import React from "react";
import FormContent from "./form-content";

const TrainingForm = (props) => {

    return(
        <FormContent data={props.data} form="training"
                     showLowCode={true} showSuggestButton={true} showDefaultButton={false}
                     defaultTab={props.defaultTab}/>
    )
};

export default TrainingForm
