import React, {useContext, useState} from "react";
import {Card, CardHeader, CardContent, CardMedia} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from "@mui/material/Grid";
import {Chip} from "@mui/material";
import InsertInvitationSharpIcon from '@mui/icons-material/InsertInvitationSharp';
import GroupIcon from '@mui/icons-material/Group';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {MyContentContext} from "../context/my-content.context";
import {USE_CASE_STATUS} from "../Constants";
import {updateUseCase} from "../api/update-use-case";
import {UserContext} from "../context/user.context";
import {AuthContext} from "../context/auth.context";
import {startClient, startServer} from "../util-dagster";
import {AllUseCasesContext} from "../context/use-cases/all-use-cases.context";
import {OwnUseCasesContext} from "../context/use-cases/own-use-cases.context";
import {CompanyUseCasesContext} from "../context/use-cases/company-use-cases.context";
import {FavoriteUseCasesContext} from "../context/use-cases/favorite-use-cases.context";
import StartTrainingModal from "./start-training-modal";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_1;
const ITEM_HEIGHT = 48;

const UseCaseCard = (props) => {
    const {addMyFavoritesContentData} = useContext(MyContentContext);
    const {user} = useContext(UserContext);
    const {token} = useContext(AuthContext);
    const {setAllUseCases} = useContext(AllUseCasesContext)
    const {setOwnUseCases} = useContext(OwnUseCasesContext)
    const {setCompanyUseCases} = useContext(CompanyUseCasesContext)
    const {setFavoriteUseCases} = useContext(FavoriteUseCasesContext)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [startTrainingProgress, setStartTrainingProgress] = useState(0)
    const [modalOpen,setModalOpen] = useState(false)

    function handleModalClose() {
        setModalOpen(false)
    }

    // modifiable field values
    const [numberOfApprovedCompanies, setNumberOfApprovedCompanies] =
        useState(props.numberOfApprovedCompanies ? props.numberOfApprovedCompanies : 0);
    const [approvedByCompanyIds, setApprovedByCompanyIds] =
        useState(props.approvedByCompanyIds ? props.approvedByCompanyIds : []);
    const [stateId, setstateId] = useState(props.stateId);
    const [isFavorite, setIsFavorite] = useState(props.favorite ? props.favorite : false);

    function changeState(useCase, newStateId) {
        let numsApproved = numberOfApprovedCompanies;
        let apprByCompanyIds = approvedByCompanyIds;
        let idxApprCurrCompany = apprByCompanyIds.findIndex((ele) => ele === user.firm_id);
        if ((newStateId === USE_CASE_STATUS.approved.id && stateId !== USE_CASE_STATUS.approved.id) ||
            (stateId === USE_CASE_STATUS.approved.id && idxApprCurrCompany === -1)) {
            numsApproved = numsApproved + 1; //Update is not fast enough

            if (idxApprCurrCompany === -1) {
                apprByCompanyIds.push(user.firm_id);
            }
        } else if (newStateId === USE_CASE_STATUS.saved.id && stateId === USE_CASE_STATUS.approved.id) {
            numsApproved = numsApproved - 1; //Update is not fast enough
            if (idxApprCurrCompany !== -1) apprByCompanyIds.splice(idxApprCurrCompany, 1);
        }
        // state should be only changed from company that created the use case
        if (user.firm_id !== useCase.createdFromCompanyID) {
            newStateId = stateId;
        }
        const formData = {
            state: newStateId,
            numberOfApprovedCompanies: numsApproved,
            approvedByCompanyIds: apprByCompanyIds,
        };
        updateUseCase(formData, token, user.id, useCase.id, false, setAllUseCases, setOwnUseCases, setCompanyUseCases, setFavoriteUseCases)
            .then(_ => {
                setstateId(newStateId);
                setApprovedByCompanyIds(apprByCompanyIds);
                setNumberOfApprovedCompanies(numsApproved);
                //close popup
                handleClose();
            }).catch(_ => {
            console.log("Error updating use case");
            //TODO: show error message
            handleClose();
        })
    }

    function changeFavorite(useCase, isFavorite) {
        const formData = {
            favorite: isFavorite,
        };
        updateUseCase(formData, token, user.id, useCase.id, false, setAllUseCases, setOwnUseCases, setCompanyUseCases, setFavoriteUseCases)
            .then(_ => {
                setIsFavorite(isFavorite);
                //close popup
                handleClose();
            }).catch(_ => {
            console.log("Error updating use case");
            //TODO: show error message
            handleClose();
        })
    }

    function startTraining(useCase) {
        setModalOpen(true)
        let currentProgress = 0;
        const sleep = (delay, resolveValue) => new Promise((resolve) => {setTimeout(() => resolve(resolveValue), delay);});

        startServer(12)
            .then((res) => {
                console.log(res);
                setStartTrainingProgress(33);
                return sleep(6000);
            })
            .then((res) => {
                return startClient(1);
            })
            .then((res) => {
                console.log(res);
                setStartTrainingProgress(66);
                return sleep(3000);
            })
            .then((res) => {
                return startClient(2);
            })
            .then((res) => {
                setStartTrainingProgress(100);
                console.log(res);
                handleModalClose();
                handleClose();
            })
            .catch((err) => {
                console.log(err);
                handleModalClose();
                handleClose();
            });
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const showDetails = (useCase) => {
        handleClose();

        props.onClick !== undefined ? props.onClick() : alert("Keine Detailansicht möglich")
    };

    // TODO: status uebersetzen
    let status = USE_CASE_STATUS.saved.translation;
    status = USE_CASE_STATUS[stateId]?.translation;
    let color = USE_CASE_STATUS.saved.color;
    color = USE_CASE_STATUS[stateId]?.color;

    return (
        <>
            <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', zIndex: 100}}>
                <CardHeader sx={{zIndex: 150}}
                            title={props.heading}
                            action={
                                <IconButton aria-label="weitere Funktionen" color="white"
                                            id="long-button"
                                            aria-controls={open ? 'long-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleClick}>
                                    <MoreVertIcon/>
                                </IconButton>
                            }
                            color="black"/>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '25ch',
                        },
                    }}
                >
                    {!isFavorite &&
                        <MenuItem key="1" onClick={changeFavorite.bind(null, props.useCase, true)}>
                            zu Favoriten hinzufügen
                        </MenuItem>
                    }
                    {isFavorite &&
                        <MenuItem key="1" onClick={changeFavorite.bind(null, props.useCase, false)}>
                            von Favoriten entfernen
                        </MenuItem>
                    }
                    {(stateId === USE_CASE_STATUS.saved.id ||
                            (stateId === USE_CASE_STATUS.approved.id && approvedByCompanyIds.findIndex((ele) => ele === user.firm_id) === -1)) &&
                        <MenuItem key="2" onClick={showDetails.bind(null, props.useCase)}>
                            Use Case bearbeiten
                        </MenuItem>
                    }
                    {(stateId === USE_CASE_STATUS.saved.id ||
                            (stateId === USE_CASE_STATUS.approved.id && approvedByCompanyIds.findIndex((ele) => ele === user.firm_id) === -1)) &&
                        <MenuItem key="3" onClick={changeState.bind(null, props.useCase, USE_CASE_STATUS.approved.id)}>
                            Use Case freigeben
                        </MenuItem>
                    }
                    {(stateId === USE_CASE_STATUS.approved.id && approvedByCompanyIds.findIndex((ele) => ele === user.firm_id) !== -1) &&
                        <MenuItem key="4" onClick={changeState.bind(null, props.useCase, USE_CASE_STATUS.saved.id)}>
                            Freigabe zurücknehmen
                        </MenuItem>
                    }
                    {(stateId === USE_CASE_STATUS.approved.id && numberOfApprovedCompanies == props.useCase.participants) &&
                        <MenuItem key="5" onClick={startTraining}>
                            Use Case trainieren
                        </MenuItem>
                    }
                </Menu>
                <CardMedia sx={{zIndex: 110}} onClick={showDetails.bind(null, props.useCase)}
                           component="img"
                           src={BACKEND_URL+'/api/files/images/' + props.image}
                           alt="random"
                />
                <CardContent sx={{flexGrow: 1}}>
                    {/* First Row with detail informations */}
                    <Grid container spacing={0} color="black" height="70px"
                          sx={{p: '15px', background: '#2B2B2B', color: 'white'}}>
                        <Typography variant="body3" color="text.eigth"
                                    sx={{overflow: 'hidden', textOverflow: 'ellipsis', height: '2.6rem'}}>
                            {props.description}
                        </Typography>
                    </Grid>
                    {/* Second Row with detail informations */}
                    {/*

                */}
                    <Grid container spacing={0}
                          sx={{pl: '15px', pt: '15px', pr: '15px', pb: '5px', mr: '1'}}>
                        <Grid item xs={6} justifyContent="left" display="flex">
                            <InsertInvitationSharpIcon sx={{mr: 1}} alignmentBaseline="central"/>
                            <Typography variant="body1" component="font">
                                {props.invitedUserIds.length + props.invitedCompanyIds.length}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} justifyContent="right" display="flex">
                            <GroupIcon sx={{mr: 1}}/>
                            <Typography variant="body1" component="font">
                                {numberOfApprovedCompanies}/{props.useCase.participants}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* Third Row with detail informations */}
                    <Grid container spacing={0}
                          sx={{pl: '15px', pr: '15px', pt: '5px', pb: '15px'}}>
                        <Grid item xs={6} justifyContent="left" display="flex">
                            <CalendarMonthIcon sx={{mr: 1}}/>
                            <Typography variant="body1" component="font">
                                {new Intl.DateTimeFormat('de-DE', {
                                    dateStyle: 'medium',
                                    timeStyle: 'short'
                                }).format(new Date(props.date))}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} justifyContent="right" display="flex">
                            <Chip label={status} variant="filled"
                                  color={color}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <StartTrainingModal open={modalOpen} handleClose={handleModalClose} value={startTrainingProgress}/>
        </>

    );
}

export default UseCaseCard;
