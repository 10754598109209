import React from "react";
import FormContent from "./form-content";

const PreprocessingForm = (props) => {
    return(
        <FormContent data={props.data} form="preprocessing"
                     showLowCode={false} showSuggestButton={false} showDefaultButton={true}
                     defaultTab={props.defaultTab}/>
    )
}

export default PreprocessingForm
