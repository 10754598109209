import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import {CircularProgress, Typography} from "@mui/material";
import Box from "@mui/material/Box";

const StartTrainingModal = (props) => {

    const style = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    console.log(props.value)

    return(
        <Modal
            open={props.open}
            onClose={props.handleClose}
            BackdropProps={{
                style: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}
            }}
        >
            <Grid style={style}>
                <Box bgcolor="white.main" p={4} borderRadius={3} width="40vh" height="40vh">
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress variant="determinate" value={props.value} size={100}/>
                    </Box>
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="caption"
                            fontSize={23}
                            component="div"
                            color="text.secondary"
                        >{`${props.value}%`}</Typography>
                    </Box>
                    <Typography textAlign="center">Training wird gestartet...</Typography>
                </Box>
            </Grid>
        </Modal>
    )
}

export default StartTrainingModal