import {myThemeOptions, user1Theme, user2Theme} from "../MyTheme";

export function checkUserTheme(user) {
    if (user.firm_id === "5899")
        return user1Theme;
    else if (user.firm_id === "2545")
        return user2Theme;
    else
        return myThemeOptions;
}
