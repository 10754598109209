import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_URL_1
export async function createUseCase(formData, token) {
    console.log(formData);

    try {
        const response = await axios.request({
            url: `${BASE_URL}/api/files/create/use-case`,
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            data: formData
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}
