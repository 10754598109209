import React, {useContext, useState} from "react";
import {
    BottomNavigation,
    BottomNavigationAction,
    Step,
    StepLabel,
    stepLabelClasses,
    Stepper
} from "@mui/material";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import DoneIcon from '@mui/icons-material/Done';
import {styled} from '@mui/material/styles';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import {MyContentContext} from "../../context/my-content.context";
import {UserContext} from "../../context/user.context";
import BasicForm from "./stepper-forms/basic-form";
import TrainingForm from "./stepper-forms/training-form";
import SpecificationFrom from "./stepper-forms/specification-form";
import DataForm from "./stepper-forms/data-form";
import PreprocessingForm from "./stepper-forms/preprocessing-form";
import {createUseCase} from "../../api/create-use-case";
import {AuthContext} from "../../context/auth.context";
import {updateUseCase} from "../../api/update-use-case";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import {USE_CASE_STATUS} from "../../Constants";
import {AllUseCasesContext} from "../../context/use-cases/all-use-cases.context";
import {OwnUseCasesContext} from "../../context/use-cases/own-use-cases.context";
import {CompanyUseCasesContext} from "../../context/use-cases/company-use-cases.context";
import {FavoriteUseCasesContext} from "../../context/use-cases/favorite-use-cases.context";
import {getAllUseCases} from "../../api/get-all-use-cases";
import {getOwnUseCases} from "../../api/get-own-use-cases";
import {getCompanyUseCases} from "../../api/get-company-use-cases";
import {getFavoriteUseCases} from "../../api/get-favorite-use-cases";

const MyConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 18
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                "linear-gradient( 95deg, rgba(2,173,208,1) 0%, rgba(49,133,230,1) 75%, rgba(10,2,136,1) 100%)",
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                "linear-gradient( 95deg, rgba(2,173,208,1) 0%, rgba(49,133,230,1) 75%, rgba(10,2,136,1) 100%)",
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 5,
        border: 0,
        backgroundColor: theme.palette.white.main,
        borderRadius: 1
    }
}));

const MyStepLabel = styled(StepLabel)(({theme}) => ({
    [`& .${stepLabelClasses.label}`]: {
        color: theme.palette.black.main,
        fontSize: '1rem'
    },
    [`& .${stepLabelClasses.active}`]: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: '1rem'
    },
}));

const MyStepIconRoot = styled("div")(({theme, ownerState}) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 100,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    root: {
        zIndex: 100,
    },
    ...(ownerState.active && {
        backgroundImage: "linear-gradient(136deg, rgba(2,173,208,1) 0%, rgba(49,133,230,1) 35%, rgba(10,2,136,1) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
    }),
    ...(ownerState.completed && {
        backgroundImage:
            "linear-gradient(136deg, rgba(2,173,208,1) 0%, rgba(49,133,230,1) 35%, rgba(10,2,136,1) 100%)",
    }),
    ...(ownerState.completed && {
        backgroundImage:
            "linear-gradient(136deg, rgba(2,173,208,1) 0%, rgba(49,133,230,1) 35%, rgba(10,2,136,1) 100%)",
    })
}));

function MyStepIcon(props) {
    const {active, completed, className} = props;

    return (
        <MyStepIconRoot
            ownerState={{completed, active}}
            className={className}
        >
            {props.icon}.
        </MyStepIconRoot>
    );
}

MyStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

function getSteps() {
    return [
        "Basis",
        "Daten",
        "Preprocessing",
        "Spezifikation",
        "Training"
    ];
}

// Copy default values from owner company to current company
function getDefaultValueSpeciTrain(ownerCompanyID, currentCompanyID, data2Copy) {
    let resultObject = {...data2Copy};

    if (ownerCompanyID && currentCompanyID && ownerCompanyID !== currentCompanyID) {
        if ( !data2Copy['C'+currentCompanyID] ) {
            resultObject['C'+currentCompanyID] = {...data2Copy['C'+ownerCompanyID]}
        }
    }

    return resultObject;
}

const LinearStepper = (props) => {
    const {addMyCompanyContentData} = useContext(MyContentContext);
    const {user} = useContext(UserContext);
    const {token} = useContext(AuthContext);
    const {setAllUseCases} = useContext(AllUseCasesContext)
    const {setOwnUseCases} = useContext(OwnUseCasesContext)
    const {setCompanyUseCases} = useContext(CompanyUseCasesContext)
    const {setFavoriteUseCases} = useContext(FavoriteUseCasesContext)

    const defaultValues = props.newUseCase === true
        ? {
            user_id: user.id,
            firm_id: user.firm_id,
            file: "",
            title: "",
            description: "",
            participants: "",
            invitedUserIds: [],
            invitedCompanyIds: [],
            visibleForAll: "false",
            favorite: "false",
            anonymizing: "",
            state: USE_CASE_STATUS.saved.id,
            csvFile: ""
        } :
        {
            user_id: user.id,
            firm_id: user.firm_id,
            file: "",
            title: props.data.title,
            description: props.data.description,
            participants: props.data.participants,
            invitedUserIds: props.data.invitedUserIds,
            invitedCompanyIds: props.data.invitedCompanyIds,
            visibleForAll: props.data.visibleForAll,
            favorite: props.data.favorites,
            anonymizing: props.data.anonymizing,
            state: USE_CASE_STATUS.saved.id,
            csvFile: "",
            preprocessing: props.data.preprocessing,
            specification: getDefaultValueSpeciTrain(props?.data?.createdFromCompanyID, user.firm_id, props.data.specification),
            training: getDefaultValueSpeciTrain(props?.data?.createdFromCompanyID, user.firm_id, props.data.training),
        }

        console.log(props.data)

    const methods = useForm({defaultValues});
    const [activeStep, setActiveStep] = useState(props.editable === true ? 0 : 1);
    const steps = getSteps();
    const [useCaseID, setUseCaseID] = useState(props?.data?.id ? props.data.id : "");
    const [imageChanged, setImageChanged] = useState(false);

    function getDefaultTab(data, backupOwner = false) {
        let defaultTab = 0;
        if (!data) return defaultTab;

        let process_data = data["C" + user.firm_id];
        if (!process_data && backupOwner) {
            process_data = data["C" + props?.data?.createdFromCompanyID];
        }

        if (process_data && process_data.epochen) {
            defaultTab = 2;
        } else if (process_data && process_data.gitURL) {
            defaultTab = 1;
        } else if (process_data && process_data.code) {
            defaultTab = 0;
        }

        return defaultTab;
    }

    function getStepContent(step, editable, data) {
        switch (step) {
            case 0:
                return <BasicForm editable={editable} data={data} onImageChange={setImageChanged}/>;
            case 1:
                return <DataForm data={data} useCaseID={useCaseID}/>;
            case 2:
                return <PreprocessingForm data={props.data} defaultTab={getDefaultTab(data ? data.preprocessing : 0)}/>;
            case 3:
                return <SpecificationFrom data={props.data} defaultTab={getDefaultTab(data ? data.specification : 0, true)}/>;
            case 4:
                return <TrainingForm data={props.data} defaultTab={getDefaultTab(data ? data.training : 0, true)}/>;
            default:
                return "unknown step";
        }
    }

    const extractProps = (data, props, array2String) => {
        const filteredObj = {};

        for (const prop of props) {
            if (data.hasOwnProperty(prop)) {
                if (array2String && (prop === "invitedUserIds" || prop === "invitedCompanyIds")) {
                    let newArrayString = data[prop].join(',')
                    filteredObj[prop] = newArrayString
                } else {
                    filteredObj[prop] = data[prop];
                }

            }
        }

        return filteredObj;
    }


    const handleNext = async (data, event) => {

        let formData
        let res

        if (activeStep === 0 && useCaseID === "") {
            formData = extractProps(data, ["user_id", "firm_id", "file", "title", "description", "participants", "invitedUserIds", "invitedCompanyIds", "favorite", "state", "anonymizing"], true);
            res = await createUseCase(formData, token);
            if(res !== undefined) {
                setUseCaseID(res.substring(36, res.length));
                getAllUseCases(user.id, token, setAllUseCases)
                    .then( (res) => {
                        return getOwnUseCases(user.id, token, setOwnUseCases)
                    })
                    .then( (res) => {
                        return getCompanyUseCases(user.id, token, setCompanyUseCases)
                    })
                    .then( (res) => {
                        return getFavoriteUseCases(user.id, token, setFavoriteUseCases)
                    })
                    .catch(error => console.log(error))
            }
        } else if (activeStep === 0 && useCaseID !== "" && props.editable) {
            formData = extractProps(data, ["title", "file", "description", "participants", "invitedUserIds", "invitedCompanyIds", "favorite"]);
            await updateUseCase(formData, token, user.id, useCaseID, imageChanged, setAllUseCases, setOwnUseCases, setCompanyUseCases, setFavoriteUseCases);
        } else if (activeStep === 1 && useCaseID !== "") {
            formData = extractProps(data, ["anonymizing"]);
            await updateUseCase(formData, token, user.id, useCaseID, false,setAllUseCases, setOwnUseCases, setCompanyUseCases, setFavoriteUseCases);
        } else if (activeStep === 2 && useCaseID !== "") {
            formData = extractProps(data, ["preprocessing"]);
            await updateUseCase(formData, token, user.id, useCaseID, false,setAllUseCases, setOwnUseCases, setCompanyUseCases, setFavoriteUseCases);
        } else if (activeStep === 3 && useCaseID !== "") {
            formData = extractProps(data, ["specification"]);
            await updateUseCase(formData, token, user.id, useCaseID,false,setAllUseCases, setOwnUseCases, setCompanyUseCases, setFavoriteUseCases);
        } else if (activeStep === 4 && useCaseID !== "") {
            formData = extractProps(data, ["training"]);
            await updateUseCase(formData, token, user.id, useCaseID, false, setAllUseCases, setOwnUseCases, setCompanyUseCases, setFavoriteUseCases);
        }

        if (activeStep === steps.length - 1) {
            props.onClose();
            addMyCompanyContentData(data);
        } else {
            setActiveStep(activeStep + 1);
        }
    };

        const handleBack = () => {
            setActiveStep(activeStep - 1);
        };

        return (
            <Box sx={{height: "100%"}}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<MyConnector/>}>
                    {steps.map((step, index) => {
                        return (
                            <Step key={index}>
                                <MyStepLabel StepIconComponent={MyStepIcon} color="error">{step}</MyStepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                <FormProvider {...methods}>
                    <form style={{height: "70vh", width: "100%", overflow: "scroll"}}
                          onSubmit={methods.handleSubmit(handleNext)}>
                        <Box>
                            {getStepContent(activeStep, props.editable, props.data, useCaseID)}
                        </Box>
                        <BottomNavigation showLabels sx={{
                            height: "48px", width: "inherit", position: "fixed", pt: 1,
                            bottom: 6, display: "flex", justifyContent: "space-between",
                            borderTop: '1px solid #D1D1D1'
                        }}>
                            <BottomNavigationAction disabled={activeStep === 0} onClick={handleBack}
                                                    label="zurück" icon={<ArrowCircleLeftOutlinedIcon/>}/>
                            {/*{activeStep === steps.length - 1 &&*/}
                            {/*    <BottomNavigationAction type="submit" name="approve"*/}
                            {/*                        label={"freigeben"}*/}
                            {/*                        icon={<AssignmentTurnedInIcon/>}*/}
                            {/*    />}*/}
                            <BottomNavigationAction type="submit" name="save"
                                                    label={activeStep === steps.length - 1 ? "speichern" : "weiter"}
                                                    icon={activeStep === steps.length - 1 ? <DoneIcon/> :
                                                        <ArrowCircleRightOutlinedIcon/>}/>
                        </BottomNavigation>
                    </form>
                </FormProvider>

            </Box>
        );
}

export default LinearStepper;
